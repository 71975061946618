import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import wb from "./registerServiceWorker";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://46e33a67c6424791b8ca3cdf906a9495@o1147938.ingest.sentry.io/6243775",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "app.trillwave.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


Vue.prototype.$workbox = wb;
Vue.prototype.$http = axios;
if(process.env.NODE_ENV!="development"){
  Vue.prototype.$baseurl = "https://api.trillwave.com/v1"
  Vue.prototype.$authurl = "https://auth.trillwave.com"
}else{
  Vue.prototype.$baseurl = "http://localhost:3000/v1"
  Vue.prototype.$authurl = "http://localhost:6060"
}

Vue.prototype.$companionurl = "http://localhost:3020/companion"
Vue.config.productionTip = false
import VueProgressiveImage from 'vue-progressive-image'
Vue.use(VueProgressiveImage)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
