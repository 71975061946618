import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router/index.js";
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from "vuex-multi-tab-state";
import jwt_decode from "jwt-decode";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    }),
    createMultiTabState()
  ],
  state: {
    user: {},
    files: null,
    beats: null
  },
  mutations: {
    setFiles(state, payload) {
      state.files = payload;
    },
    setBeats(state, payload) {

      state.beats = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    logoutUser(state) {
      state.user = [];
      state.files = null;
      state.beats = null;

    }
  },
  actions: {
    setUpUserAction({ commit }, payload) {
      commit("setUser", payload);
      router.push("/d/overview");
    },
    signOut({ commit }) {
      commit("logoutUser");
      if (process.env.NODE_ENV != "development") {
        window.location.href = "https://auth.trillwave.com"
      } else {
        window.location.href = "http://localhost:6060"
      }

    },

  },
  modules: {
  },
  getters: {
    getUser: state => {
      return state.user;
    },
    getFiles: state => {
      return state.files;
    },
    isLoggedIn: state => {
      let res;

      if (state.user.id) {
        res = true;
        var decoded = jwt_decode(state.user.token);

        if (decoded.exp < Date.now() / 1000) {
          res = false;
        }
      } else {
        res = false;
      }
      return res;
    }
  }
})
