import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "@/store";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: "/d/overview"
  },
  {
    path: '/receipt/:id',
    name: 'Receipt',
    component: () => import('../views/Receipt.vue')
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('../views/AuthCallback.vue')
  },
  {
    path: '/d',
    name: 'app',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: "overview",
        path: "/d/overview",
        component: () => import('../views/overview.vue')
      },
      {
        name: "files",
        path: "/d/files",
        component: () => import("../views/Files.vue")
      },
      {
        name: "analytics",
        path: "/d/analytics",
        component: () => import("../views/Analytics.vue")
      },
      {
        name: "beats",
        path: "/d/beats",
        component: () => import("../views/Beats.vue")
      },
      {
        name: "messages",
        path: "/d/messages",
        component: () => import("../views/Messages.vue")
      },
      {
        name: "customers",
        path: "/d/customers",
        component: () => import("../views/Customers.vue")
      },
      {
        name: "customersview",
        path: "/d/customers/:id",
        component: () => import("../views/CustomerView.vue")
      },
      {
        name: "store",
        path: "/d/store",
        component: () => import("../views/Store.vue")
      },
      {
        name: "licences",
        path: "/d/licences",
        component: () => import("../views/Licences.vue")
      },
      ,
      {
        name: "licencesnew",
        path: "/d/licences/new",
        component: () => import("../views/LicencesEdit.vue")
      },
      {
        name: "editlicences",
        path: "/d/licences/:id",
        component: () => import("../views/LicencesEdit.vue")
      },
      {
        name: "products",
        path: "/d/products",
        component: () => import("../views/Products.vue")
      },
      {
        name: "settings",
        path: "/d/settings",
        component: () => import("../views/Settings.vue")
      },
      {
        name: "newbeat",
        path: "/d/beats/new",
        component: () => import("../views/BeatsEdit.vue")
      },
      {
        name: "editbeat",
        path: "/d/beats/:id",
        component: () => import("../views/BeatsEdit.vue")
      },
      {
        name: "billing",
        path: "/d/billing",
        component: () => import("../views/Billing.vue")
      },
      {
        name: "sales",
        path: "/d/sales",
        component: () => import("../views/Sales.vue")
      },
      {
        name: "salesview",
        path: "/d/sales/:id",
        component: () => import("../views/SalesView.vue")
      },

    ],
    component: () => import('../views/dashboard.vue'),
    redirect: "/d/overview"
  }, {
    path: '/auth',
    name: 'auth',
    children: [
      {
        name: "login",
        path: "/auth/login",
        component: () => import("../views/AuthLogin.vue")
      },
      {
        name: "register",
        path: "/auth/signup",
        component: () => import("../views/AuthSignup.vue")
      },

    ],
    component: () => import('../views/Auth.vue'),
    redirect: "/auth/login"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    //Nprogress.start();
    const loggedin = store.getters.isLoggedIn;

    if (!store.getters.isLoggedIn) {
      if (process.env.NODE_ENV != "development") {
        
        window.location.href = "https://auth.trillwave.com"
      } else {
        window.location.href = "http://localhost:6060"
      }
      //next({ path: "/auth/login" });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});
export default router
