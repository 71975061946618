<template>
<div id="app">
    <router-view />
</div>
</template>

<style>

@import "./assets/css/base.css";
@import "./assets/css/main.css";
@import "./assets/css/uppy.css";
@import "./assets/css/animate.css";
</style>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'


require('bootstrap')
require('popper.js')

/*
global.jQuery = require('jquery')
var $ = global.jQuery
// var jQuery = global.jQuery;
window.$ = $
import $ from "jquery/dist/jquery.slim.js"
*/

export default {
    name: 'menuHeader',
    created() {
        
    }
}
</script>
